/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle, ContactForm, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Obchodní podmínky"}>
        <SiteHeader />

        <Column className="pt--60" name={"fotografky"} style={{"paddingBottom":0}} layout={"l30"}>
        </Column>


        <Column className="--l8w" name={"78hegerhzzk"} style={{"paddingTop":0,"paddingBottom":0}}>
        </Column>


        <Column className="pb--60" name={"pxncv62dlai"} style={{"paddingTop":29}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box" content={"Ceník"}>
              </Title>

              <Text className="text-box fs--16" style={{"marginTop":0}} content={"platný od 1.8.2023"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <Subtitle className="subtitle-box" style={{"maxWidth":468}} content={"<span style=\"font-weight: bold;\">Rodinné, portrétní, párové, těhotenské</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":1554,"paddingLeft":0,"paddingRight":0}} content={"cca 40 min focení\n<br>10 retušovaných fotografií <br>(v elektronické podobě v barevně i černobílé variantě)<br>trvalá webová galerie<br><br>&nbsp;Každá další fotografie nad rámec balíčku 200,- <br><br>&nbsp;Dodací doba je zhruba 4-6 týdnů od Vámi zaslaných výběrů a připsání platby na účet."}>
              </Text>

              <Subtitle className="subtitle-box ff--2" content={"<span style=\"font-style: italic;\">2 500 Kč</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":1554,"paddingLeft":0,"paddingRight":0}} content={"cca 60 min focení\n<br>25 retušovaných fotografií <br>(v elektronické podobě v barevně i černobílé variantě)<br>trvalá webová galerie<br><br>&nbsp;Každá další fotografie nad rámec balíčku 200,- <br><br>&nbsp;Dodací doba je zhruba 4-6 týdnů od Vámi zaslaných výběrů a připsání platby na účet."}>
              </Text>

              <Subtitle className="subtitle-box ff--2" content={"<span style=\"font-style: italic;\">5 000 Kč</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box" style={{"marginTop":66}} content={"<span style=\"font-weight: bold;\">Děti- batolata, narozeninové, rodinné</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":782,"paddingRight":0}} content={"cca 1 hodina focení<br>12 retušovaných fotografií <br>(v elektronické podobě v barevně i černobílé variantě)<br>&nbsp; trvalá webová galerii\n<br><br>&nbsp;Každá další fotografie nad rámec balíčku 200,- <br><br>Dodací doba je zhruba 4-6 týdnů od Vámi zaslaných výběrů a připsání platby na účet.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"3 000 Kč"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":782,"paddingRight":0}} content={"cca 1,5 hodiny focení<br>30 retušovaných fotografií <br>(v elektronické podobě v barevně i černobílé variantě)<br>&nbsp; trvalá webová galerii\n<br><br>&nbsp;Každá další fotografie nad rámec balíčku 200,- <br><br>Dodací doba je zhruba 4-6 týdnů od Vámi zaslaných výběrů a připsání platby na účet.<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"6 000 Kč"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obchodnipodminkyfoto"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"Obchodní podmínky"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":858}} content={"Následující obchodní podmínky upravují v souladu s ustanovením § 1751 odst. 1 zákona č. 89/2012 Sb., občanského zákoníku vzájemná práva a povinnosti smluvních stran vzniklé v souvislosti nebo na základě smlouvy, popř. dohody o poskytování fotografických služeb (dále jen \"dohoda\") mezi fotografem a objednatelem (dále jen \"klient\"). Ustanovení rozdílná od obchodních podmínek je možné sjednat ve smlouvě. Tato rozdílná ujednání ve smlouvě mají přednost před ustanoveními těchto obchodních podmínek.&nbsp;<br><br>Obchodní podmínky jsou součástí dohody mezi fotografem a klientem. Při objednání fotografických služeb má klient povinnost seznámit se s těmito obchodními podmínkami. Potvrzením termínu focení klient stvrzuje přijetí těchto podmínek a srozumění s nimi, stejně tak srozumění s autorským přístupem a fotografickým stylem, s kterým fotograf pracuje a nemá proti němu žádné výhrady. Klient o obchodních podmínkách obeznámí všechny osoby zúčastněné focení.&nbsp;<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Objednávka služeb"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":884,"paddingRight":0}} content={"Na focení se objednává klient telefonicky, na WhatsApp nebo emailem. Focení lze domluvit pouze venku v okolí Stoda. Pokud si klient přeje focení na nějakém Vámi vybraném místě, napíše to fotografovi při objednání. Fotograf pak zhodnotí zda li je to v jeho možnostech. V ostatních případech vybírá místo fotograf. V případě špatného počasí, se lze domluvit na přeložení focení v exteriéru na nejbližší možný termín.&nbsp;<br><br>Při objednání klient napíše o jaké focení má zájem, kolik osob dorazí, popřípadě jak jsou staré děti. Na newborn focení se klient objednává už v těhotenství, po porodu zašle SMS fotografovi a navzájem se domluví na přesném termínu focení.&nbsp;&nbsp;<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Příchod na focení"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":914}} content={"Na focení klient dorazí alespoň o 10 min dříve. Většina focení je plánovaná těsně za sebou a není moc prostoru na zpoždění. Pokud se někde klient zdrží, dá tuto skutečnost vědět fotografovi. V případě velkého zpoždění je možné, že bude nutné termín zrušit.&nbsp;<br><br>Pokud se klient nemůže nebo nechce na fotografování dostavit a bude požadovat jeho zrušení nebo náhradní termín, je jeho povinností oznámit telefonicky fotografovi tuto skutečnost s dostatečným časovým předstihem. Klient bere na vědomí, že pokud bude žádat o náhradní termín, bude mu nabídnut nejbližší možný volný termín s ohledem na aktuální fotografovu vytíženost. Může se stát, že již žádný vhodný termín na focení mít nemusí (zjm. u newborn focení).&nbsp;<br><br>Pokud klient nedorazí na focení bez omluvy, má fotograf právo po 15 minutách od domluveného termínu focení zrušit a opustit smluvené místo focení.<br><br>Pozdní příchod klienta není důvodem k prodloužení časového harmonogramu focení.\n&nbsp;<br>"}>
              </Text>

              <Subtitle className="subtitle-box" content={"Co sebou a na sebe"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":954}} content={"Na focení dorazte upravení, sladění a v čistém a nejlépe světlém oblečení bez výrazných potisků. Ženy alespoň trošku nalíčené. Pokud si lakujete nehty tak je vždy mějte upravené a neoloupané, na fotografiích to opravdu nevypadá hezky. Uvnitř se fotí bez ponožek tak prosím o kontrolu laku i na nožkách. Výrazné rtěnky vezměte sebou, kdyby byla potřeba ji upravit.<br><br>Dětem prosím nehtíky odlakujte a odstraňte tetování. Na půjčení v ateliéru mám šaty i pro děti, vždy pište dopředu ať je připravím nebo vezmu na exteriérové focení. Prosím nedávejte dětem oblečení s oblíbenými pohádkami a potisky, opravdu to na fotkách nevypadá dobře.<br><br>Na těhotenské focení raději volte jednobarevné a obtaženější vršky, vynikne tak bříško. Vhodné jsou delší svetry, šaty, body i jednodílné plavky. Hezké jsou také džínsy, ne těhotenské. Nevadí že je nezapnete, vypadá to pěkně i rozepnuté. Spodní prádlo mějte vždy sladěné v bílé i černé variantě. Barevně se pokuste sladit s partnerem. Vezměte také botičky, fotku ultrazvuku či jiný doplněk. Před focením dejte pozor ať si na bříško neotlačíte oblečení nebo pás od auta nebo pás od těhotenských kalhot.&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"cb2pyrnq5z"} style={{"paddingTop":72}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"font-weight: bold;\">Jdu do toho!</span>"}>
              </Title>

              <Subtitle className="subtitle-box mt--16" content={"Kontaktní formulář"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":740}} content={"Chcete o našich službách vědět víc nebo se objednat? <br>Zanechte nám tu na Vás kontaktní informace a my se Vám ozveme."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"backgroundColor":"rgba(229,214,207,1)"}}>
              
              <ContactForm className="--shape2 --shadow4 w--500" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Souhlasím se zpracováním osobních informací","type":"checkbox","required":true},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20" name={"patka"} style={{"paddingTop":35,"backgroundColor":"rgba(224, 224, 224, 1)"}} layout={"l1"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Ateliér Tůtojc Stod"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":394}} content={"Stella Jarošová<br>IČO:&nbsp;08663696<br>Sídlo:\nMířovice 10,\n333 01 Ves Touškov<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":398}} srcSet={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}